import { useState, useEffect } from 'react';
import Modal from '../../ui/Modal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Box, Grid, TextField, Typography, Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import CollapsibleTable from '../../components/CollapsibleTable';

function AcceptedRequests() {
    const SERVER = process.env.REACT_APP_SERVER;
    const [file, setFile] = useState();
    const [acceptedStudents, setAcceptedStudents] = useState([]);
    const [uploadModal, setUploadModal] = useState({ active: false, student: null });
    const [prof, setProf] = useState();

    useEffect(() => {
        preluareProfesor();
        preluareStudenti();
    }, []);

    const preluareProfesor = async () => {
        const professorResponse = await fetch(`${SERVER}user`, {
            method: 'GET',
            headers: {
                Authorization: localStorage.getItem('token'),
                'Content-Type': 'application/json',
            },
        });
        const professorData = await professorResponse.json();
        setProf(professorData.instanta);
    }

    const preluareStudenti = async () => {
        try {
            const studResp = await fetch(`${SERVER}colaborari`, {
                method: 'GET',
                headers: {
                    Authorization: localStorage.getItem('token'),
                    'Content-Type': 'application/json',
                },
            });
            const studData = await studResp.json();
            const accepted = [];
            for (let student of studData.acceptati) {
                const { titlu } = student.Solicitaris[0];
                const specializare = student.Specializari.nume;
                const cereri = student.Cereris.length > 0 ? student.Cereris.map(c => c.titlu).join("; ") : 'Nu are cereri.';
                accepted.push({ ...student, titlu, specializare, cereri });
            }
            setAcceptedStudents(accepted);
        }catch(e){
            console.error(e)
        }
    }

    const onDownload = student => {
        if (student.Cereris.length <= 0) {
            toast.error("Studentul nu are cerere încărcată.");
            return;
        }
        fetch(`${SERVER}cerere/${student.id}`, {
            method: 'GET',
            headers: {
                Authorization: localStorage.getItem('token'),
                'Content-Type': 'application/json',
            },
        })
            .then(res => res.json())
            .then(data => {
                if (!data?.fisier) {
                    alert('Nu există fișier încărcat!');
                    return;
                }
                const downloadLink = document.createElement('a');
                const fileName = `Cerere disertație ${student.nume + ' ' + student.prenume} - ${data.titlu}.pdf`;
                downloadLink.href = data.fisier;
                downloadLink.download = fileName;
                downloadLink.click();
            });
    };

    const onUpload = student => {
        setUploadModal({ active: true, student: student });
    };

    const onFileChange = e => {
        if (e.target.files[0].size / 1024 / 1024 > 15) {
            alert('File too big');
            e.target.value = null;
            return;
        }
        setFile(e.target.files[0]);
    };

    const renderButtons = (row) => (
        <>
            <Button disabled={row.Cereris.length <= 0} startIcon={<DownloadIcon />} onClick={() => { onDownload(row); }}>Descarcă</Button>
            <Button disabled={row.Cereris.length <= 0 || row.Cereris[row.Cereris.length-1].e_semnat == 1} startIcon={<UploadFileIcon />} onClick={() => {
                document.documentElement.scrollTop = 0;
                onUpload(row);
            }} >Încarcă cererea semnată</Button>
        </>
    );

    const uploadCerere = () => {
        if (!file) { toast.error('Alege fișierul pe care vrei sa îl încarci'); return; }
        const fileReader = new FileReader();
        let base64;
        fileReader.onload = e => {
            base64 = e.target.result;
            fetch(SERVER + 'cerere', {
                method: 'PATCH',
                headers: {
                    Authorization: localStorage.getItem('token'),
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    fisier: base64,
                    idStudent: uploadModal.student.id,
                }),
            })
                .then(res => res.json())
                .then(data => { });
        };
        fileReader.readAsDataURL(file);
        setUploadModal({ active: false, student: null });
        toast.success('Cererea s-a încarcat cu succes!');
    }

    return (
        <Box sx={{ margin: "100px 10px" }}>
            {prof && prof.grad_didactic ? (
                prof.numar_studenti_maxim > 0 ? (
                    <>
                        {acceptedStudents.length > 0 ? <CollapsibleTable rows={acceptedStudents} renderButtons={renderButtons} columns={[
                            { label: 'Nr.', id: 'nr' },
                            { label: 'Email', id: 'email' },
                            { label: 'Specializare', id: 'specializare' }, 
                            { label: 'An', id: 'an' },
                            { label: 'Grupă', id: 'grupa' },
                            { label: 'Titlu solicitare', id: 'titlu' },
                            { label: 'Titlu cereri', id: 'cereri' },
                            { label: '', id: 'buttons' }
                        ]} /> : <Box sx={{ backgroundColor: "white", padding: "20px", margin: "100px 20px" }}>Nu aveți cereri.</Box>}
                        {uploadModal.active && (
                            <Modal sx={{ height: 500 }} open={true} closed={() => { setFile(undefined); setUploadModal({ active: false, student: null }) }}>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography align="center" component="h5" variant="h5" sx={{ mb: 2 }}>
                                        Sunteți sigur că doriți să încărcați cererea studentului{' '}
                                        {uploadModal.student.nume + ' ' + uploadModal.student.prenume} ? Aceasta trebuie să fie semnată!
                                    </Typography>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}  >
                                        <TextField margin='normal' type='file' accept='.pdf' required fullWidth onChange={onFileChange} />
                                        <Button variant="contained" color="success" disabled={!file} sx={{ width: '45%', m: 1, mt: 3 }}
                                            onClick={uploadCerere}>  Încarcă
                                        </Button>
                                        <Button
                                            color="error" variant='outlined' sx={{ width: '45%', m: 1, mt: 3 }}
                                            onClick={() => { setFile(null); setUploadModal({ active: false, student: null }) }} >
                                            Nu încă
                                        </Button>
                                    </Box>
                                </Grid>
                            </Modal>
                        )}
                    </>
                ) : (
                    <Box sx={{ backgroundColor: "white", padding: "20px", margin: "20px" }}>
                        Vă rugăm așteptați ca directorul de departament să vă seteze limita maximă admisă de studenți.
                    </Box>
                )
            ) : (
                <Box sx={{ backgroundColor: "white", padding: "20px", margin: "20px" }}>
                    Completați datele personale în pagina de profil, din meniul lateral.
                </Box>
            )}
        </Box>
    );
}

export default AcceptedRequests;