import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import { Autocomplete } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const theme = createTheme();
const currentYear = new Date().getFullYear();

const getInmatriculareYears = () => {
  const isInmatricularePeriod = (month, day) => {
    const today = new Date();
    return today.getMonth() + 1 >= month && today.getDate() >= day;
  };

  const years = [];
  const maxYear = isInmatricularePeriod(9, 1) ? currentYear - 2 : currentYear - 3;
  for (let year = maxYear; year >= 2000; year--) {
    years.push(year);
  }

  return years;
};

function Profile(props) {
  const forme = ['IF', 'ID'];
  const ani = ['anul III', 'an suplimentar'];

  const validationSchema = Yup.object().shape({
    grupa: Yup.number().required('Grupa este obligatorie.').min(1000, 'Grupa nu este validă.').max(9999, 'Grupa nu este validă.'),
    telefon: Yup.string().required('Telefonul este obligatoriu.').matches(/^\d{10}$/, 'Telefonul nu este valid.'),
    an_inmatriculare: Yup.number().required('Selectați anul de înmatriculare.').min(2000, "Selectați anul de înmatriculare."),
    forma_invatamant: Yup.string().required("Selectați forma de învățământ.").oneOf(forme, "Selectați forma de învățământ."),
    an: Yup.string().required("Selectați anul de studiu.").oneOf(ani, "Selectați anul de studiu."),
    specializare: Yup.string().required("Selectați specializarea.")
  });

  const [specializari, setSpecializari] = useState([]);
  const [specializariObj, setSpecializariObj] = useState([]);
  const [specializare, setSpecializare] = useState('Informatică Economică');
  const [idSpecializare, setIdSpecializare] = useState(1);
  const [grupa, setGrupa] = useState('');
  const [an, setAn] = useState('anul III');
  const [telefon, setTelefon] = useState('');
  const [forma_invatamant, setForma] = useState('IF');
  const [an_inmatriculare, setAnInmat] = useState(getInmatriculareYears()[0]);

  const SERVER = process.env.REACT_APP_SERVER;

  useEffect(() => {
    const getBackendData = () => {
      fetch(`${SERVER}specializare`, {
        method: 'GET',
        headers: {
          Authorization: localStorage.getItem('token'),
          'Content-Type': 'application/json',
        },
      })
        .then((res) => res.json())
        .then((dataS) => {
          setSpecializari(dataS.map((spec) => spec.nume));
          setSpecializariObj(dataS);

          fetch(`${SERVER}user`, {
            method: 'GET',
            headers: {
              Authorization: localStorage.getItem('token'),
              'Content-Type': 'application/json',
            },
          })
            .then((res) => res.json())
            .then((data) => {
              if (data.grupa) setGrupa(data.grupa);
              if (data.telefon) setTelefon(data.telefon);
              if (data.an) setAn(data.an);
              if (data.an_inmatriculare) setAnInmat(data.an_inmatriculare);
              if (data.forma_invatamant) setForma(data.forma_invatamant);
              if (data.id_specializare) {
                setIdSpecializare(data.id_specializare);
                setSpecializare(dataS.find(d => d.id == data.id_specializare).nume);
              }
            });
        });

    };

    getBackendData();
  }, [SERVER]);

  const handleKeyPress = (e) => {
    const charCode = e.charCode;
    if (charCode < 48 || charCode > 57) {
      e.preventDefault(); // Prevent input if character is not a digit (0-9)
    }
  };

  const sendData = (values) => {
    if (values.an_inmatriculare === getInmatriculareYears()[0] && values.an === 'an suplimentar') {
      toast.error("Nu poți fi an suplimentar!");
      return
    }
    fetch(`${SERVER}user`, {
      method: 'PATCH',
      headers: {
        Authorization: localStorage.getItem('token'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...values,
        id_specializare: specializariObj.find((s, i) => s.nume === values.specializare)?.id
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) toast.error(data.error);
        else toast.success(`Date actualizate cu succes!`);
      })
      .catch((e) => {
        toast.error(`Actualizare eșuată!`);
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container component='main' sx={{ height: '100vh', marginTop: "50px" }}>
        <CssBaseline />
        <Grid item xs={false} sm={2} md={3} />
        <Grid item xs={12} sm={8} md={6} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 4,
              mx: 2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <lottie-player
              src='https://assets9.lottiefiles.com/packages/lf20_qudievat.json'
              background='transparent'
              speed='1'
              style={{ width: '100px', height: '100px' }}
              loop
              autoplay
            ></lottie-player>
            <Typography align='center' component='h1' variant='h5'>
              Editare date personale
            </Typography>
            <Formik enableReinitialize initialValues={{
              grupa: grupa,
              telefon: telefon,
              an_inmatriculare: an_inmatriculare,
              an: an,
              forma_invatamant: forma_invatamant,
              specializare: specializare
            }} validationSchema={validationSchema} onSubmit={sendData}>
              {({ setFieldValue }) => (
                <Form style={{ width: "100%" }}  >
                  <Field
                    value={grupa}
                    margin='normal'
                    fullWidth
                    type='number'
                    label='Grupa'
                    name='grupa'
                    onKeyPress={handleKeyPress}
                    onChange={e => { setFieldValue("grupa", e.target.value); setGrupa(e.target.value); }}
                    as={TextField}
                  />
                  <ErrorMessage name="grupa" component="div" className="text-danger" />
                  <Field
                    margin='normal'
                    fullWidth
                    id='telefon'
                    type='text'
                    label='Telefon'
                    name='telefon'
                    as={TextField}
                    onKeyPress={handleKeyPress}
                    value={telefon}
                    onChange={e => { setFieldValue("telefon", e.target.value); setTelefon(e.target.value) }}
                  />
                  <ErrorMessage name="telefon" component="div" className="text-danger" />
                  <Autocomplete
                    margin='normal'
                    fullWidth
                    id="an_inmatriculare"
                    value={parseInt(an_inmatriculare)}
                    className='mt-3'
                    onChange={(e, value) => { setFieldValue("an_inmatriculare", value); setAnInmat(value) }}
                    options={getInmatriculareYears()}
                    getOptionLabel={(option) => option.toString()}
                    renderInput={(params) => <TextField {...params} label="Anul de înmatriculare" variant="outlined" />}
                    name="an_inmatriculare"
                    renderOption={(props, option) => (
                      <li {...props}>
                        {option}
                      </li>
                    )}
                  />
                  <ErrorMessage name="an_inmatriculare" component="div" className="text-danger" />
                  <Autocomplete
                    disablePortal
                    id='forma_invatamant'
                    options={forme}
                    margin='normal'
                    fullWidth
                    value={forma_invatamant}
                    onChange={(e, value) => { setFieldValue("forma_invatamant", value); setForma(value); if (value == 'ID') { setFieldValue("specializare", specializare) } }}
                    name='forma_invatamant'
                    label='Forma de învățământ'
                    getOptionLabel={(option) => option.toString()}
                    renderInput={(params) => (
                      <TextField
                        margin='normal'
                        fullWidth
                        {...params}
                        label='Forma de învățământ'
                      />
                    )}
                    renderOption={(props, option) => (
                      <li {...props}>
                        {option}
                      </li>
                    )}
                  />
                  <ErrorMessage name="forma_invatamant" component="div" className="text-danger" />
                  <Autocomplete
                    disablePortal
                    id='an'
                    options={ani}
                    margin='normal'
                    fullWidth
                    name='an'
                    label='An'
                    value={an}
                    onChange={(e, value) => { setFieldValue("an", value); setAn(value) }}
                    getOptionLabel={(option) => option.toString()}
                    renderInput={(params) => (
                      <TextField margin='normal' fullWidth {...params} label='An' />
                    )}
                    renderOption={(props, option) => (
                      <li {...props}>
                        {option}
                      </li>
                    )}
                  />
                  <ErrorMessage name="an" component="div" className="text-danger" />
                  {forma_invatamant !== 'ID' && (
                    <>
                      <Autocomplete
                        disablePortal
                        id='specializare'
                        options={specializari}
                        margin='normal'
                        required
                        isOptionEqualToValue={(option, value) =>
                          option.value === value?.value
                        }
                        fullWidth
                        onChange={(e, value) => { setFieldValue("specializare", value); setSpecializare(value) }}
                        value={specializare}
                        getOptionLabel={(option) => option.toString()}
                        name='specializare'
                        label='Specializare'
                        renderInput={(params) => (
                          <TextField
                            margin='normal'
                            fullWidth
                            {...params}
                            label='Specializare'
                          />
                        )}
                        renderOption={(props, option) => (
                          <li {...props}>
                            {option}
                          </li>
                        )}
                      />
                      <ErrorMessage name="specializare" component="div" className="text-danger" />
                    </>
                  )}

                  {forma_invatamant === 'ID' && (
                    <TextField
                      margin='normal'
                      fullWidth
                      value={specializare}
                      id='specializare'
                      label='Specializare'
                      name='specializare'
                      autoComplete='Specializare'
                      aria-readonly
                    />
                  )}
                  <Button
                    fullWidth
                    type='submit'
                    variant='contained'
                    color='success'
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Salveaza
                  </Button>
                </Form>
              )}
            </Formik>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default Profile;
