import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

function Row(props) {
  const { nrCrt, row, hiddenColumns, renderButtons, columns} = props;
  const [open, setOpen] = React.useState(false);
  console.log(renderButtons(row))
  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        {hiddenColumns.length > 0 && (
          <TableCell>
            {open ? (
              <KeyboardArrowUpIcon sx={{ cursor: 'pointer' }} onClick={() => setOpen(!open)} />
            ) : (
              <KeyboardArrowDownIcon sx={{ cursor: 'pointer' }} onClick={() => setOpen(!open)} />
            )}
          </TableCell>
        )}
        <TableCell>{nrCrt}</TableCell>
        {columns.filter(k => k.label !== 'Nr.').map(key => (
          !hiddenColumns.includes(key.id) && (
            <TableCell key={key.id} align="left">
              {row[key.id]?.toString()}
            </TableCell>
          )
        ))}
        {!hiddenColumns.includes('buttons') && (
          <TableCell align="left">{renderButtons(row)}</TableCell>
        )}
      </TableRow>
      {(hiddenColumns.length > 0 || hiddenColumns.includes('buttons')) && (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={columns.length + 1}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="p" gutterBottom sx={{ fontWeight: 'bold' }} component="div">
                  Detalii
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableBody>
                    {hiddenColumns.map(key => (
                      key !== 'buttons' ? (
                        <TableRow key={key}>
                          <TableCell>{key}</TableCell>
                          <TableCell>{row[key]}</TableCell>
                        </TableRow>
                      ) : (
                        <TableRow key="buttons">
                          <TableCell colSpan={2}>{renderButtons(row)}</TableCell>
                        </TableRow>
                      )
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.object.isRequired,
  hiddenColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
  renderButtons: PropTypes.func.isRequired,
};

export default function CollapsibleTable({ columns, rows, renderButtons, condition }) {
  const [hiddenColumns, setHiddenColumns] = React.useState([]);
  const tableContainerRef = React.useRef();
  
  React.useEffect(() => {
    const handleResize = () => {
      if (tableContainerRef.current) {
        const tableWidth = tableContainerRef.current.offsetWidth;
        const availableWidth = tableWidth - 60;
        let totalWidth = 0;
        const hiddenCols = [];

        for (let col of columns) {
          totalWidth += col.width || 120;
          if (totalWidth > availableWidth) {
            hiddenCols.push(col.id);
          }
        }

        setHiddenColumns(hiddenCols);
      }
    };

    const observer = new ResizeObserver(handleResize);
    if (tableContainerRef.current) {
      observer.observe(tableContainerRef.current);
    }

    handleResize();

    return () => {
      observer.disconnect();
    };
  }, [columns]);

  return (
    <TableContainer component={Paper} ref={tableContainerRef}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            {hiddenColumns.length > 0 && <TableCell />}
            {columns.map(col => (
              !hiddenColumns.includes(col.id) && (
                <TableCell key={col.id} align="left" sx={{ fontWeight: 'bold' }}>
                  {col.label}
                </TableCell>
              )
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, i) => (
            <Row
              key={row.id} 
              nrCrt={i + 1}
              columns={columns}
              row={row}
              hiddenColumns={hiddenColumns}
              renderButtons={renderButtons}
              condition={condition}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

CollapsibleTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    width: PropTypes.number,
  })).isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  renderButtons: PropTypes.func.isRequired,
};
