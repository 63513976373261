import React from 'react';
import { Typography, Button, Container, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function Unauthorized() {
    const navigate = useNavigate();

    return (
        <Container className='text-center'>
            <Paper className="p-4 m-5" elevation={7}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Acces interzis! (401)
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Nu ești autorizat pentru pagina asta.
                </Typography>
                <Button variant="contained" color="primary" onClick={() => navigate("/")}>
                    Înapoi.
                </Button>
            </Paper>
        </Container>
    );
};

export default Unauthorized;