import React, { useState, useEffect } from 'react';
import { Stepper, Box, Text, Title } from '@mantine/core';
import GeneratePage from './GeneratePage';
import RequestPage from './RequestPage';
import DownloadPage from './DownloadPage';

function StudPage() {
  const SERVER = process.env.REACT_APP_SERVER;
  const [active, setActive] = useState(1);
  const [student, setStudent] = useState();
  const [refresh, setRefresh] = useState(false);
  const [cerereSemnata, setCerereSemnata] = useState(false);
  const [areCerere, setAreCerere] = useState(false);
  const [professor, setProfessor] = useState();
  const [solicitare, setSolicitare] = useState(null);

  const requestOptions = (method) => {
    return {
      method,
      headers: {
        Authorization: localStorage.getItem('token'),
        'Content-Type': 'application/json',
      }
    };
  };

  const getBackendData = async () => {
    const areCerereSemnata = async () => {
      const response = await fetch(`${SERVER}cerere`, requestOptions('GET'));
      const data = await response.json();
      if (data && data.data && data.data.e_semnat === 1) setCerereSemnata(true);
      if (data && data.data) setAreCerere(true);
    };

    const res = await fetch(SERVER + 'user', requestOptions('GET'));
    const studentData = await res.json();
    setStudent(studentData);
    if (studentData.e_repartizat) {
      areCerereSemnata(studentData);
    }
    if (!studentData.poateTrimite) {
      const statusRes = await fetch(SERVER + 'solicitare/status', requestOptions('GET'));
      const solicitareData = await statusRes.json();
      setSolicitare(solicitareData.solicitare);
      try {
        const resProf = await fetch(`${SERVER}user/profesor/${solicitareData.solicitare.id_profesor}`, requestOptions('GET'));
        const dataProf = await resProf.json();
        setProfessor(dataProf.data);
      } catch (e) {
        console.error(e)
      }
    }
  };

  useEffect(() => {
    getBackendData();
  }, [refresh]);

  useEffect(() => {
    if (!student) {
      return;
    }
    if (!student.grupa || !student.an || !student.id_specializare || !student.an_inmatriculare || !student.telefon) {
      setActive(0);
      return;
    }
    if (student.e_repartizat === 0) {
      if (student.poateTrimite) {
        setActive(1);
        return;
      }
    } else {
      if (areCerere) {
        setActive(3);
      } else setActive(2);
    }

  }, [student, professor, areCerere, cerereSemnata]);

  return (
    <Box bg="white" p="50px" m="100px 20px">
      <Stepper active={active} onStepClick={setActive}>
        <Stepper.Step label="Profil" description="Completarea profilului" allowStepSelect={false}>
          <Text>Completează-ți profilul regăsit în meniul din stânga, pentru a putea
            vizualiza profesorii disponibili pentru specializarea ta.</Text>
        </Stepper.Step>
        <Stepper.Step label="Solicită o colaborare" description="Alege-ți un coordonator" allowStepSelect={false}>
          <RequestPage student={student} refresh={() => setRefresh(!refresh)} requestOptions={requestOptions} />
        </Stepper.Step>
        <Stepper.Step label="Încarcă cererea" description="Cererea ta PDF" allowStepSelect={areCerere && cerereSemnata}>
          <GeneratePage student={student} refresh={() => setRefresh(!refresh)} solicitare={solicitare}
            requestOptions={requestOptions} professor={professor} />
        </Stepper.Step>
        <Stepper.Step label="Descarcă cererea" description="Semnată de coordonator" allowStepSelect={areCerere}>
          <DownloadPage student={student} cerereSemnata={cerereSemnata} afterDownload={() => setActive(4)} />
          {cerereSemnata && <Box align="center">
            <Text>Dorești să schimbi titlul lucrării tale?</Text>
            <Text>Întoarce-te la pasul anterior și trimite o nouă cerere.</Text>
          </Box>}
        </Stepper.Step>
        <Stepper.Completed>
          <Box align="center">
            <Title mt="15px">Felicitări, ai terminat!</Title>
            <Text>Succes în elaboarea lucrării.</Text>
            <Text>Întoarce-te la pasul anterior pentru a descărca cererea ta din nou.</Text>
          </Box>
        </Stepper.Completed>
      </Stepper>
    </Box>
  );
}

export default StudPage;