import React, { useState, useEffect } from 'react';
import { Button, Box, Title, Text, FileInput, Input, Grid, TextInput } from '@mantine/core';
import { toast } from 'react-toastify';
import MyDocument from '../../components/Document';

function GeneratePage({ student, solicitare, professor, requestOptions, refresh }) {
    const SERVER = process.env.REACT_APP_SERVER;
    const [file, setFile] = useState();
    const [cerereSemnata, setCerereSemnata] = useState(false);
    const [areCerere, setAreCerere] = useState(false);
    const [titluCerere, setTitluCerere] = useState('');
    const [titluIncarcare, setTitluIncarcare] = useState('');

    const verificaCerere = async () => {
        const res = await fetch(SERVER + 'cerere/titlu', { ...requestOptions('GET') });
        if (!res.ok) {
            toast.error(`Eșuare încărcare!`);
        }

        const data = await res.json();
        if (data && data.titlu && data.titlu.titlu) { 
            setTitluIncarcare(data.titlu.titlu) 
        } else {
            setTitluIncarcare(solicitare?.titlu);
        }
    }

    useEffect(() => {
       student && solicitare && professor && verificaCerere();
    }, [student, solicitare, professor]);

    const adaugaCerere = () => {
        fetch(SERVER + 'cerere/titlu', {
            method: 'POST',
            headers: {
                Authorization: localStorage.getItem('token'),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                titlu: titluCerere
            }),
        })
            .then((res) => {
                if (!res.ok) {
                    toast.error(`Eșuare încărcare!`);
                }
                if (res.ok) {
                    toast.success(`Ai salvat titlul: ${titluCerere}`);
                }
                return res.json();
            })
            .then((data) => { verificaCerere() });
    }

    const onFileChange = (e) => {
        const uploadedFile = e;
        if (uploadedFile.size / 1024 / 1024 > 15) {
            toast.error('Fișierul este prea mare!');
            return;
        }
        const name = uploadedFile.name;
        const parts = name.split(".");
        const extension = parts[parts.length - 1];
        if (extension !== "pdf") {
            toast.error("Puteți încărca doar pdf!");
            return;
        }
        setFile(uploadedFile);
    };

    const onUpload = () => {
        if (!file) {
            toast.error('Alege fișierul pe care vrei să îl încarci!');
            return;
        }
        if (titluIncarcare.length === 0) {
            return toast.error('Titlul nu poate fi nul');
        }
        const fileReader = new FileReader();
        let base64;
        fileReader.onload = (e) => {
            base64 = e.target.result;
            fetch(SERVER + 'cerere', {
                method: 'POST',
                headers: {
                    Authorization: localStorage.getItem('token'),
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    titlu: titluIncarcare,
                    fisier: base64,
                    idProfesor: professor.id,
                }),
            })
                .then((res) => {
                    if (!res.ok) {
                        toast.error(`Eșuare încărcare!`);
                    }
                    if (res.ok) {
                        toast.success(`Încărcat cu succes!`);
                        setAreCerere(true);
                        setCerereSemnata(false);
                        refresh();
                    }
                    return res.json();
                })
                .then((data) => { });
        };
        fileReader.readAsDataURL(file);
    };

    return (
        <Box bg="white" mt="15px" align="center">
            <Grid>
                <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                    <Title order={3}>  Bine ai venit, {student && student.nume + ' ' + student.prenume}.</Title>
                    <Text>Profesorul tău coordonator este {professor && professor.nume + ' ' + professor.prenume}.</Text>
                    <Text>Solicitarea ta a fost cu titlul: {solicitare && solicitare.titlu}</Text>
                    <TextInput
                        value={titluCerere}
                        onChange={(event) => setTitluCerere(event.currentTarget.value)} withAsterisk
                        label="Introdu titlul licenței, generează cererea precompletată și semnează fișierul." 
                        placeholder="Titlul licenței"
                        size="md" mt="15px"
                        style={() => ({
                            width: '100%',
                            maxWidth: '500px',
                            '@media (maxWidth: 600px)': {
                                width: '100%',
                            }
                        })}
                    />
                    {titluIncarcare ? <Text>Ai salvat deja titlul '{titluIncarcare}'.</Text> : <Text>Nu ai salvat niciun titlu.</Text>}

                    <Button style={() => ({
                        marginBottom: "25px", marginTop: "10px", width: '100%', maxWidth: '500px',
                        '@media (max-width: 600px)': { width: '100%' }
                    })} disabled={titluCerere.length < 1} onClick={adaugaCerere} variant='contained'>Salvează titlul</Button>
                    {student && <MyDocument
                        className='doc'
                        student={student}
                        professor={professor}
                        titlu={titluIncarcare}
                    ></MyDocument>}
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                    <Input.Wrapper style={() => ({
                        width: '100%',
                        maxWidth: '500px',
                        '@media (max-width: 600px)': {
                            width: '100%',
                        }
                    })} mt="15px">
                        <FileInput onChange={onFileChange}
                            label="Încarcă aici cererea semnată."
                            placeholder="Cerere semnată" withAsterisk
                        />
                    </Input.Wrapper>

                    <Button style={() => ({
                        marginBottom: "25px", marginTop: "10px", width: '100%', maxWidth: '500px',
                        '@media (max-width: 600px)': { width: '100%' }
                    })} onClick={onUpload}
                        disabled={titluIncarcare.length === 0 || !file || (!cerereSemnata && areCerere)}>
                        Încarcă cererea
                    </Button>

                    <lottie-player
                        src='https://assets1.lottiefiles.com/packages/lf20_jxgqawba.json'
                        background='transparent'
                        speed='1'
                        style={{ width: '200px', height: '200px' }}
                        loop
                        autoplay
                    ></lottie-player>
                </Grid.Col>
            </Grid>
        </Box>
    );
}

export default GeneratePage;