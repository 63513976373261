import { IconNotes, IconHelp, IconLogout, IconUser } from '@tabler/icons-react';
import classes from './NavbarSearch.module.css';
import { LinksGroup } from './LinksGroup';
import { useState } from 'react';

export function ProfBurger({ close }) {
    const SERVER = process.env.REACT_APP_SERVER;
    const [acceptedStudents, setAcceptedStudents] = useState([]);
    const [possibleStudents, setPossibleStudents] = useState([]);
    const [toSign, setToSign] = useState([]);

    const preluareStudenti = async () => {
        const studResp = await fetch(`${SERVER}colaborari`, {
            method: 'GET',
            headers: {
                Authorization: localStorage.getItem('token'),
                'Content-Type': 'application/json',
            },
        });
        const studData = await studResp.json();
        setAcceptedStudents(studData.acceptati);
        setPossibleStudents(studData.posibili)
        setToSign(studData.deSemnat);
    };

    preluareStudenti();

    const mockdata = [
        { label: 'Profil', icon: IconUser, link: '/profile' },
        {
            label: 'Colaborări',
            icon: IconNotes,
            links: [
                { label: 'În așteptare', link: '/awaiting', notifications: possibleStudents.length },
                { label: 'Acceptate', link: '/accepted', notifications: acceptedStudents.length },
                { label: 'De semnat', link: '/toSign', notifications: toSign.length }
            ],
        },
        { label: 'Ajutor', icon: IconHelp, link: '/info' },
        { label: 'Ieși din cont', icon: IconLogout, logout: true }
    ];

    const links = mockdata.map((item) => <LinksGroup initiallyOpened={true} close={close} {...item} key={item.label} />);
    return (
        <nav className={classes.navbar}>
            <div className={classes.section}>
                <div className={classes.mainLinks}>{links}</div>
            </div>
        </nav>
    );
} 