import { useState, useEffect, useRef } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Grid, Button, Typography, TextareaAutosize } from '@mui/material';
import CollapsibleTable from '../../components/CollapsibleTable';
import Modal from '../../ui/Modal';
import { ProgressCardColored } from '../../components/ProgressCard';

function AwaitingRequests() {
    const SERVER = process.env.REACT_APP_SERVER;
    const [refresh, setRefresh] = useState(false);
    const [possibleStudents, setPossibleStudents] = useState([]);
    const [acceptedStudents, setAcceptedStudents] = useState([]);
    const [acceptRefuseModal, setAcceptRefuseModal] = useState({ active: false, btn: null });
    const [prof, setProf] = useState();
    const motivRef = useRef();

    useEffect(() => {
        preluareStudenti();
        preluareProfesor();
    }, [refresh]);

    const preluareProfesor = async () => {
        const professorResponse = await fetch(`${SERVER}user`, {
            method: 'GET',
            headers: {
                Authorization: localStorage.getItem('token'),
                'Content-Type': 'application/json',
            },
        });
        const professorData = await professorResponse.json();
        setProf(professorData.instanta);
    }

    const preluareStudenti = async () => {
        const studResp = await fetch(`${SERVER}colaborari`, {
            method: 'GET',
            headers: {
                Authorization: localStorage.getItem('token'),
                'Content-Type': 'application/json',
            },
        });
        const studData = await studResp.json();
        if (studResp.ok) {
            const possible = [];
            for (let student of studData.posibili) {
                console.log('f',student)
                const { titlu, mesaj } = student.Solicitaris[0];
                const specializare = student.Specializari.nume;
                possible.push({ ...student, titlu, mesaj, specializare });
            }
            const accepted = [];
            for (let student of studData.acceptati) {
                const { titlu } = student.Solicitaris[0];
                const specializare = student.Specializari.nume;
                const cereri = student.Cereris.length > 0 ? student.Cereris.map(c => c.titlu).join("; ") : 'Nu are cereri.';
                accepted.push({ ...student, titlu, specializare, cereri });
            }
            setAcceptedStudents(accepted);
            setPossibleStudents(possible);
        }
    }

    const sendRequest = (studentId, isAccepted) => {
        fetch(SERVER + 'solicitare', {
            method: 'PATCH',
            headers: {
                Authorization: localStorage.getItem('token'),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ idStudent: studentId, raspuns: isAccepted }),
        })
            .then(res => {
                if (res.ok) {
                    return res.json();
                }
            })
            .then(data => setRefresh(!refresh));
    };

    const handleAccept = (row) => {
        document.documentElement.scrollTop = 0;
        setAcceptRefuseModal({ active: true, btn: 'acceptați', student: row });
    };

    const handleReject = (row) => {
        document.documentElement.scrollTop = 0;
        setAcceptRefuseModal({ active: true, btn: 'Refuză', student: row });
    };

    const renderButtons = (row) => (
        <>
            <Box>
                <Button color="success" startIcon={<CheckCircleIcon />} onClick={() => handleAccept(row)}>Acceptă</Button>
            </Box>
            <Box>
                <Button color="error" startIcon={<CancelIcon />} onClick={() => handleReject(row)}>Refuză</Button>
            </Box>
        </>
    );

    return (
        <Box sx={{ margin: "100px 10px" }}>
            {prof && prof.grad_didactic ? (
                prof.numar_studenti_maxim > 0 ? (
                    <>
                        {acceptedStudents && prof && <ProgressCardColored text="Număr studenți acceptați" used={acceptedStudents.length} total={prof?.numar_studenti_maxim} />}
                        {possibleStudents.length > 0 ?
                            <CollapsibleTable rows={possibleStudents} renderButtons={renderButtons} columns={[
                                { label: 'Nr.', id: 'nr' },
                                { label: 'Email', id: 'email' },
                                { label: 'Specializare', id: 'specializare' },
                                { label: 'An', id: 'an' },
                                { label: 'Grupă', id: 'grupa' },
                                { label: 'Titlu', id: 'titlu' },
                                { label: 'Mesaj', id: 'mesaj' },
                                { label: '', id: 'buttons' }
                            ]} /> : <Box sx={{ backgroundColor: "white", padding: "20px", margin: "20px" }}>Nu aveți solicitări.</Box>}
                        {acceptRefuseModal.active && (
                            <Modal sx={{ height: '20vh' }} open={true} closed={() => setAcceptRefuseModal({ active: false, btn: '' })} >
                                <Grid>
                                    <Typography align="center" component="h5" variant="h5" sx={{ mb: 2 }}>
                                        Sunteți sigur că doriți să {acceptRefuseModal.btn === 'Refuză' ? 'refuzați' : 'acceptați'}?
                                    </Typography>
                                    <Box>
                                        {acceptRefuseModal.student.e_repartizat === 1 ? (
                                            <TextareaAutosize margin="normal" required aria-label="empty textarea"
                                                placeholder="Introduceți mesajul pe care doriți să-l transmiteți studentului..."
                                                name="" id="" cols="60" rows="10" ref={motivRef} minRows={10}
                                                style={{ width: '100%', resize: 'none', padding: '0.7em', fontFamily: 'sans', fontSize: '1.2em' }} maxRows={10}
                                            />
                                        ) : ('')}
                                        <Button variant="contained" color={acceptRefuseModal.btn === 'Refuză' ? 'error' : 'success'} sx={{ width: '45%', m: '2.5%' }}
                                            onClick={() => {
                                                if (acceptRefuseModal.btn === 'Refuză') {
                                                    setPossibleStudents(prevState => {
                                                        const prevStuds = [...prevState];
                                                        prevStuds.splice(prevStuds.indexOf(acceptRefuseModal.student), 1);
                                                        return prevStuds;
                                                    });
                                                    sendRequest(acceptRefuseModal.student.id, false);
                                                    setAcceptRefuseModal({ active: false });
                                                }
                                                if (acceptRefuseModal.btn === 'acceptați') {
                                                    setPossibleStudents(prevState => {
                                                        const prevStuds = [...prevState];
                                                        prevStuds.splice(prevStuds.indexOf(acceptRefuseModal.student), 1);
                                                        return prevStuds;
                                                    });
                                                    sendRequest(acceptRefuseModal.student.id, true);
                                                }
                                                setAcceptRefuseModal({ active: false });
                                            }} > {acceptRefuseModal.btn === 'Refuză' ? 'Refuză' : 'Acceptă'}
                                        </Button>
                                        <Button variant="outlined" color="error" sx={{ width: '45%', m: '2.5%' }}
                                            onClick={() => setAcceptRefuseModal({ active: false, btn: '' })} >  NU
                                        </Button>
                                    </Box>
                                </Grid>
                            </Modal>
                        )}
                    </>
                ) : (
                    <Box sx={{ backgroundColor: "white", padding: "20px", margin: "20px" }}>
                        Vă rugăm așteptați ca directorul de departament să vă seteze limita maximă admisă de studenți.
                    </Box>
                )
            ) : (
                <Box sx={{ backgroundColor: "white", padding: "20px", margin: "20px" }}>
                    Completați datele personale în pagina de profil, din meniul lateral.
                </Box>
            )}
        </Box>
    );
}

export default AwaitingRequests;