import { useState, useEffect, useRef } from 'react';
import Modal from '../../ui/Modal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CancelIcon from '@mui/icons-material/Cancel';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Box, Grid, TextField, TextareaAutosize, Typography, Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import CollapsibleTable from '../../components/CollapsibleTable';

function ToSign() {
    const SERVER = process.env.REACT_APP_SERVER;
    const [refresh, setRefresh] = useState(false);
    const [file, setFile] = useState();
    const [toSign, setToSign] = useState([]);
    const [acceptRefuseModal, setAcceptRefuseModal] = useState({ active: false, btn: null });
    const [uploadModal, setUploadModal] = useState({ active: false, student: null });
    const [prof, setProf] = useState();
    const motivRef = useRef();

    useEffect(() => {
        preluareProfesor();
        preluareStudenti();
    }, [refresh]);

    const preluareProfesor = async () => {
        const professorResponse = await fetch(`${SERVER}user`, {
            method: 'GET',
            headers: {
                Authorization: localStorage.getItem('token'),
                'Content-Type': 'application/json',
            },
        });
        const professorData = await professorResponse.json();
        setProf(professorData.instanta);
    }

    const preluareStudenti = async () => {
        const studResp = await fetch(`${SERVER}colaborari`, {
            method: 'GET',
            headers: {
                Authorization: localStorage.getItem('token'),
                'Content-Type': 'application/json',
            },
        });
        const studData = await studResp.json();
        const toSign = [];
        for (let student of studData.deSemnat) {
            const titlu = student.Cereris.length > 0 ? student.Cereris[student.Cereris?.length - 1]?.titlu : 'Nu are cerere.';
            toSign.push({ ...student, titlu });
        }
        setToSign(toSign);
    }

    const onDownload = student => {
        fetch(`${SERVER}cerere/${student.id}`, {
            method: 'GET',
            headers: {
                Authorization: localStorage.getItem('token'),
                'Content-Type': 'application/json',
            },
        })
            .then(res => {
                return res.json();
            })
            .then(data => {
                if (!data?.fisier) {
                    alert('Nu există fișier încărcat!');
                    return;
                }
                const downloadLink = document.createElement('a');
                const fileName = `Cerere disertație ${student.nume + ' ' + student.prenume
                    } - ${data.titlu}.pdf`;
                downloadLink.href = data.fisier;
                downloadLink.download = fileName;
                downloadLink.click();
            });
    };

    const onUpload = student => {
        setUploadModal({ active: true, student: student });
    };

    const onFileChange = e => {
        if (e.target.files[0].size / 1024 / 1024 > 15) {
            toast.error('Fișierul e prea mare!');
            return;
        }
        const name =  e.target.files[0].name
        if(!name.includes("pdf")){
            toast.error("Puteți încărca doar pdf!");
            return;
        }
       setFile(e.target.files[0]);
    };

    const refuzaCererea = async (student, motiv) => {
        if (motiv) {
            fetch(SERVER + 'user/mail', {
                method: 'POST',
                headers: {
                    Authorization: localStorage.getItem('token'),
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ id_student: student.id, text: motiv }),
            }).then(res => {
                if (res.ok) {
                    return res.json();
                }
            });
        }
        const res = await fetch(`${SERVER}cerere/${student.id}`, {
            headers: {
                Authorization: localStorage.getItem('token'),
            },
        });
        const cerere = await res.json();
        const response = await fetch(`${SERVER}cerere/${cerere.id}`, {
            headers: {
                Authorization: localStorage.getItem('token'),
            },
            method: 'DELETE',
        });

        const data = await response.json();
        if (!response.ok) {
            console.log(data.error);
            return;
        }
        toast.info('Ați refuzat cererea!');
    };

    const renderButtons = (row) => (
        <>
            <Button color="error" startIcon={<CancelIcon />}
                onClick={e => {
                    e.preventDefault(); setAcceptRefuseModal({ active: true, btn: 'Refuză', student: row, cerere: true });
                }}>Refuză</Button>
            <Button startIcon={<DownloadIcon />} onClick={() => { onDownload(row); }} > Descarcă </Button>
            <Button startIcon={<UploadFileIcon />} onClick={() => {
                document.documentElement.scrollTop = 0; onUpload(row);
            }}>Încarcă cererea semnată</Button>
        </>
    );

    const uploadCerere = () => {
        if (!file) { toast.error('Alege fișierul pe care vrei sa îl încarci'); return; }
        const fileReader = new FileReader();
        let base64;
        fileReader.onload = e => {
            base64 = e.target.result;
            fetch(SERVER + 'cerere', {
                method: 'PATCH',
                headers: {
                    Authorization: localStorage.getItem('token'),
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    fisier: base64,
                    idStudent: uploadModal.student.id,
                }),
            })
                .then(res => res.json())
                .then(data => { });
        };
        fileReader.readAsDataURL(file);
        setUploadModal({ active: false, student: null });
        toast.success('Cererea s-a încarcat cu succes!');
        setRefresh(!refresh)
    }

    return (
        <Box sx={{ margin: "100px 10px" }}>
          {prof && prof.grad_didactic ? (
           (prof.numar_studenti_maxim > 0 ?  <>
            {toSign.length ? <CollapsibleTable rows={toSign} renderButtons={renderButtons} columns={[
              { label: 'Nr.', id: 'nr' },
              { label: 'Email', id: 'email' },
              { label: 'An', id: 'an' },
              { label: 'Grupă', id: 'grupa' },
              { label: 'Titlu', id: 'titlu' },
              { label: '', id: 'buttons' }
          ]} /> : <Box sx={{ backgroundColor: "white", padding: "20px", margin: "100px 20px" }}>Nu aveți cereri de semnat.</Box>}
          {uploadModal.active && (
              <Modal sx={{ height: 500 }} open={true} closed={() => { setFile(undefined); setUploadModal({ active: false, student: null }) }}>
                  <Grid item xs={12} sm={12} md={12}>
                      <Typography align="center" component="h5" variant="h5" sx={{ mb: 2 }}>
                          Sunteți sigur că doriți să încărcați cererea studentului{' '}
                          {uploadModal.student.nume + ' ' + uploadModal.student.prenume} ? Aceasta trebuie să fie semnată!
                      </Typography>
                      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}  >
                          <TextField margin='normal' type='file' accept='.pdf' required fullWidth onChange={onFileChange} />
                          <Button variant="contained" color="success" disabled={!file} sx={{ width: '45%', m: 1, mt: 3 }}
                              onClick={uploadCerere}>  Încarcă
                          </Button>
                          <Button
                              color="error" variant='outlined' sx={{ width: '45%', m: 1, mt: 3 }}
                              onClick={() => { setFile(null); setUploadModal({ active: false, student: null }) }} >
                              Nu încă
                          </Button>
                      </Box>
                  </Grid>
              </Modal>
          )}
          {acceptRefuseModal.active && (
              <Modal sx={{ height: '20vh' }} open={true} closed={() => setAcceptRefuseModal({ active: false, btn: '' })} >
                  <Grid>
                      <Typography align="center" component="h5" variant="h5" sx={{ mb: 2 }}>
                          Sunteți sigur că doriți să {acceptRefuseModal.btn === 'Refuză' ? 'refuzați' : 'acceptați'}?
                      </Typography>
                      <Box>
                          {acceptRefuseModal.student.e_repartizat === 1 ? (
                              <TextareaAutosize margin="normal" required aria-label="empty textarea"
                                  placeholder="Introduceți mesajul pe care doriți să-l transmiteți studentului..."
                                  name="" id="" cols="60" rows="10" ref={motivRef} minRows={10}
                                  style={{ width: '100%', resize: 'none', padding: '0.7em', fontFamily: 'sans', fontSize: '1.2em' }} maxRows={10}
                              />
                          ) : ('')}
                          <Button
                              variant="contained"
                              color={acceptRefuseModal.btn === 'Refuză' ? 'error' : 'success'}
                              sx={{ width: '45%', m: '2.5%' }}
                              onClick={() => {
                                  refuzaCererea(acceptRefuseModal.student, motivRef.current.value);
                                  setAcceptRefuseModal({ active: false });
                              }}  >
                              {acceptRefuseModal.btn === 'Refuză' ? 'Refuză' : 'Acceptă'}
                          </Button>
                          <Button
                              variant="outlined"
                              color="error"
                              sx={{ width: '45%', m: '2.5%' }}
                              onClick={() =>
                                  setAcceptRefuseModal({ active: false, btn: '' })
                              }>NU</Button>
                      </Box>
                  </Grid>
              </Modal>
          )}
          </> :  <Box sx={{ backgroundColor: "white", padding: "20px", margin: "20px" }}>
          Vă rugăm așteptați ca directorul de departament să vă seteze limita maximă admisă de studenți.</Box>)
          ) : <Box sx={{ backgroundColor: "white", padding: "20px", margin: "20px" }}>
                Completați datele personale în pagina de profil, din meniul lateral.</Box>}
        </Box>
    );
}

export default ToSign;