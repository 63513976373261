import LoginForm from './pages/LoginPage';
import { useState, useEffect } from 'react';
import Unauthorized from './pages/Unauthorized';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import jwt_decode from 'jwt-decode';
import Profile from './pages/student/Profile';
import ProfProfile from './pages/profesor/ProfProfile';
import InfoProf from './pages/profesor/InfoProf';
import Info from './pages/student/Info';
import { AppNavbar } from './ui/AppNavbar';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import StudPage from './pages/student/StudPage';
import AwaitingRequests from './pages/profesor/AwaitingRequests';
import AcceptedRequests from './pages/profesor/AcceptedRequests';
import ToSign from './pages/profesor/ToSign';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isProfessor, setIsProfessor] = useState(false);
  const [user, setUser] = useState();

  const callbackLogin = () => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const data = jwt_decode(token);
        const numarMinute = (Date.now() / 1000 - data.iat) / 60;
        if (!data || numarMinute >= 30) {
          return localStorage.removeItem('token');
        }
        setIsLoggedIn(true);
        setIsProfessor(data.email.split('@')[1] !== 'stud.ase.ro');
      } catch (e) {
        console.error(e)
      }
    }
  };

  useEffect(() => {
    callbackLogin();
  }, [isLoggedIn]);

  return (
    <>
      <AppNavbar isLoggedIn={isLoggedIn} isStudent={!isProfessor} />
      <Routes>
        <Route exact path="/unauthorized" element={<Unauthorized />} />
        <Route exact path="/" element={<PrivateRoute callbackLogin={callbackLogin} logged={isLoggedIn} />}>
          {isProfessor ? <Route exact path="/" element={<AwaitingRequests />} /> :
            <Route exact path="/" element={<StudPage />} />}
        </Route>
        <Route exact path="/awaiting" element={<PrivateRoute callbackLogin={callbackLogin} logged={isLoggedIn} />}>
          {isProfessor ? <Route exact path="/awaiting" element={<AwaitingRequests />} /> :
            <Route exact path="/awaiting" element={<StudPage />} />}
        </Route>
        <Route exact path="/accepted" element={<PrivateRoute callbackLogin={callbackLogin} logged={isLoggedIn} />}>
          {isProfessor ? <Route exact path="/accepted" element={<AcceptedRequests />} /> :
            <Route exact path="/accepted" element={<StudPage />} />}
        </Route>
        <Route exact path="/toSign" element={<PrivateRoute callbackLogin={callbackLogin} logged={isLoggedIn} />}>
          {isProfessor ? <Route exact path="/toSign" element={<ToSign />} /> :
            <Route exact path="/toSign" element={<StudPage />} />}
        </Route>
        <Route exact path="/profile" element={<PrivateRoute callbackLogin={callbackLogin} logged={isLoggedIn} />}>
          {isProfessor ? <Route exact path="/profile" element={<ProfProfile />} /> :
            <Route exact path="/profile" element={<Profile />} />}
        </Route>
        <Route exact path="/info" element={<PrivateRoute callbackLogin={callbackLogin} logged={isLoggedIn} />}>
          {isProfessor ? <Route exact path="/info" element={<InfoProf />} /> :
            <Route exact path="/info" element={<Info />} />}
        </Route>
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <ToastContainer />
    </>
  );
}

const PrivateRoute = ({ logged, callbackLogin }) => {
  return logged ? <Outlet /> : <LoginForm onLogin={callbackLogin} />
};

export default App;