import { useRef, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { Button } from '@mantine/core';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import csie from './csie.png'

const MyDocument = (props) => {
  const componentRef = useRef();
  const SERVER = process.env.REACT_APP_SERVER;
  const [programeObject, setProgrameObject] = useState([]);
  const [isGenerating, setIsGenerating] = useState(false);

  useEffect(() => {
    getSpecs();
    let programe = [];
    for (let sp of programeObject) {
      if (sp.id !== props.student.id_specializare) {
        programe.push(false);
      } else programe.push(true);
    }
  }, [props.student.id_specializare]);

  const getSpecs = () => {
    fetch(`${SERVER}specializare`, {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('token'),
        'Content-Type': 'application/json',
      },
    })
      .then(res => res.json())
      .then(data => {
        setProgrameObject(data);
      });
  };

  const handleDownloadPDF = () => {
    setIsGenerating(true);
    const input = document.getElementById('toPrint');
    input.style.position = "absolute";
    input.style.top = "-10000px";
    input.style.left = "-10000px";
    input.style.display = "block";
    input.style.width = "210mm";
    input.style.height = "297mm";
    input.style.margin = "0";
    input.style.padding = "0"
    html2canvas(input, { scale: 4 }).then(canvas => {
      input.style.display = "none";
      const imgData = canvas.toDataURL("image/jpeg");
      const pdf = new jsPDF('p', 'mm', 'a4');
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, 'JPEG', 0, 0, pdfWidth, pdfHeight);
      pdf.save(`Cerere_licenta_${props.student.nume}_${props.student.prenume}.pdf`);
      setIsGenerating(false);
    }).catch(error => {
      console.error('Error generating PDF' + error);
    });
  };

  const today = new Date();
  const computeUniversitaryYear = () => {
    const today = new Date();
    let inmatriculationYear, startingYear, endingYear = 0;
    const isInUniversitaryYear = (month, day) => {
      return today.getMonth() + 1 >= month && today.getDate() >= day;
    };
    if (isInUniversitaryYear(9, 1)) {
      inmatriculationYear = today.getFullYear() - 2;
      startingYear = today.getFullYear();
    } else {
      inmatriculationYear = today.getFullYear() - 3;
      startingYear = today.getFullYear() - 1;
    }
    endingYear = startingYear + 1;
    return { inmatriculationYear, startingYear, endingYear };
  };
  const universitaryYear = computeUniversitaryYear();

  let luna = parseInt(today.getMonth() + 1);
  if (luna.toString().length === 1)
    luna = '0' + parseInt(today.getMonth() + 1);
  let zi = parseInt(today.getDate());
  if (zi.toString().length === 1)
    zi = '0' + parseInt(today.getDate());

  return (
    <div>
      <Grid container justifyContent='center'>
        <Button disabled={isGenerating}
          style={{ marginBottom: "25px", marginTop: "10px", width: '100%', maxWidth: '500px', '@media (max-width: 600px)': { width: '100%' } }}
          onClick={handleDownloadPDF}
        >
          {isGenerating ? 'Se descarcă...' : 'Descarcă cererea'}
        </Button>
      </Grid>
      <div className='yourClassName' id="toPrint" ref={componentRef}>
        <div
          style={{
            fontFamily: 'Times New Roman',
            fontSize: '12px',
            color: 'black',
            background: 'white',
            paddingLeft: '2cm',
            paddingRight: '2cm',
            paddingTop: '1.5cm'
          }}
        >
          <div
            style={{ fontSize: '16px', textAlign: 'center', color: '#2e2be2' }}
          >
            <img
              id='csie'
              src={csie}
              alt='Logo'
            />
            Facultatea de Cibernetică, Statistică și <br></br>Informatică
            Economică <br></br>
            Academia de Studii Economice din București<br></br>
            <p
              style={{
                fontSize: '14px',
                textAlign: 'center',
                color: '#1a17a9',
                marginLeft: '130px',
              }}
            >
              Clădirea Virgil Madgearu Calea Dorobanți, 15-17, Sector 1,
              <br></br>
              Bucureşti, 010552, http://csie.ase.ro<br></br>
            </p>
          </div>
          <br></br>
          <div style={{ fontSize: '16px', textAlign: 'center' }}>
            Aviz Decan, &emsp;&emsp;&emsp; &emsp;&emsp;&emsp;&emsp;&emsp;Aviz
            Director Departament, &emsp;&emsp;&emsp;&emsp;&emsp;
            &emsp;&emsp;&emsp;De acord, __________________
            &emsp;&emsp;&emsp;&emsp;&emsp;____________________ &emsp;&emsp;
            &emsp;__________________ <br></br>
            &emsp;&emsp;&emsp;&emsp;&emsp;
            &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
            &emsp;&emsp;&emsp;&emsp;&emsp;(semnătura coordonatorului și data)
            <br></br>
          </div>
          <br></br>
          <p style={{ fontSize: '20px', textAlign: 'center' }}>
            <b>Domnule Decan,</b>
          </p>
          <br></br>
          <div style={{ fontSize: '16px', textAlign: 'left' }}>
            Subsemnatul/a &emsp;
            <u> {props.student.nume + ' ' + props.student.prenume} </u>&emsp;
            student/ă în {props.student.an}, în cadrul Facultății de
            Cibernetică, Statistică și Informatică Economică, forma de
            învățământ¹ <u>{props.student.forma_invatamant}</u>, specializarea:
            <br></br>
            <ul style={{ listStyleType: 'none', fontWeight: 'bold' }}>
              {programeObject &&
                programeObject.map(s => (
                  <li key={s.id}>
                    <input type="checkbox" readOnly
                      checked={s.id === props.student.id_specializare}
                    >
                    </input>
                    &emsp;{s.nume}
                  </li>
                ))}
            </ul>
            vă rog să binevoiți a-mi aproba realizarea lucrării de licență cu
            titlul:<br></br>
            <u>{props.titlu}</u>
            <br></br>
            având coordonator științific pe dl/dna.(grad didactic):
            <u>
              {props.professor &&
                ' ' + props.professor.grad_didactic +
                ' ' +
                props.professor.nume +
                ' ' +
                props.professor.prenume}
            </u>
            <br></br>
            Menționez că am fost înmatriculat/ă în programul de licență în anul²{' '}
            {props.student.an_inmatriculare}
            <br></br>
            e-mail <u>{props.student.email}</u>
            &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
            telefon {props.student.telefon}
            <br></br>
            <br></br>
            <br></br>
            Data <u>{zi + '.' + luna + '.' + new Date().getFullYear()}</u>
            &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;Semnătura
            student,<br></br>
            <br></br>
            <br></br>
            Domnului Decan al Facultății de Cibernetică, Statistică și Informatică <br></br>
            Economică <br></br>
            <br></br>_______________
          </div>
          <div style={{ fontSize: '14px', textAlign: 'left' }}>
            ¹IF-pentru învățământ cu frecvență, ID pentru învățământ la distanță
            <br></br>
            ²Pentru studenții din anul 3, anul universitar {universitaryYear.startingYear}-{universitaryYear.endingYear}, anul
            înmatriculării este {universitaryYear.inmatriculationYear}.<br></br>
            <br></br>
            *datele de contact sunt folosite exclusive pentru notificarea
            studenților în problem ce privesc pregătirea și derularea examenului
            de licență.
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyDocument;