import { Text, Progress, Card, Grid } from '@mantine/core';
import classes from './ProgressCardColored.module.css';

export function ProgressCardColored({ text, used, total }) {
    return (
        <Grid>
            <Grid.Col offset={{base: 0, md: 3, lg: 4.5}} span={{ base: 12, md: 6, lg: 3 }}>
                <Card withBorder radius="md" p="xl" mb="sm" className={classes.card}>
                    <Text fz="xs" tt="uppercase" fw={700} className={classes.title}>
                        {text}
                    </Text>
                    <Text fz="lg" fw={500} className={classes.stats}>
                        {used} / {total}
                    </Text>
                    <Progress
                        value={54.31}
                        mt="md"
                        size="lg"
                        radius="xl"
                        classNames={{
                            root: classes.progressTrack,
                            section: classes.progressSection,
                        }}
                    />
                </Card>
            </Grid.Col></Grid>
    );
}