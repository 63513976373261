import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import ArticleIcon from '@mui/icons-material/Article';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import AppSettingsAltIcon from '@mui/icons-material/AppSettingsAlt';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

const theme = createTheme();
export default function Info(props) {
  return (
    <ThemeProvider theme={theme}>
      <Grid container component='main' sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid item xs={12} sm={12} md={12} sx={{ mx: '2vw', my: '1vh', borderRadius: 2 }} elevation={6}>
          <Button
            type='button' size='large'
            startIcon={<KeyboardBackspaceIcon />}
            sx={{ mt: 3, mx: '2vw', color: 'white' }}
            onClick={() => props.setIsInfoVisible(false)}
          >
            Înapoi
          </Button>
          <Box component={Paper} elevation={6}
            sx={{
              my: '1vh',
              mb: '2vh',
              justifyContent: 'space-around',
              borderRadius: 2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ mt: '4vh', mb: '2vh', bgcolor: 'secondary.main' }}>
              <ArticleIcon />
            </Avatar>
            <Typography sx={{ mb: '4vh' }} align='center' component='h1' variant='h4'>
              Documente utile
            </Typography>
            <Typography sx={{ mb: '0.5vh' }} align='center' component='a' variant='h5' href='https://consilierstudenti.ase.ro/calendarul-studentului-2024-2025/'>
              Calendarul studentului
            </Typography>
            <Typography sx={{ mb: '0.5vh' }} align='center' component='a' variant='h5' href='https://mefc.ase.ro/baza-legislativa-studii-universitare-de-licenta/'>
              Documentele din baza legislativă ASE cu privire la examenul de licență
            </Typography>
            <Typography sx={{ mb: '6vh' }} align='center' component='a' variant='h5' href='https://www.net.ase.ro/antiplagiat/'>
              Antiplagiat
            </Typography>
          </Box>
          <Box component={Paper} elevation={6}
            sx={{
              my: '1vh',
              mb: '2vh',
              justifyContent: 'space-around',
              borderRadius: 2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <lottie-player src="https://assets10.lottiefiles.com/packages/lf20_wtbkltdx.json" background="transparent" speed="1" style={{ width: '100px', height: '100px' }} loop autoplay></lottie-player>
            <Typography sx={{ mb: '4vh' }} align='center' component='h1' variant='h4'>
              Inițializare
            </Typography>
            <Box sx={{ width: '100%', px: '4vw' }}>
              <Typography sx={{ mb: '0.5vh' }} align='left' fontWeight='bold' component='h5' variant='h5'>
                1. Accesarea contului
              </Typography>
              <Typography sx={{ mb: '6vh' }} align='left' component='h5' variant='h5'>
                Autentificarea în cadrul acestei platformei de asociere pentru licență se realizează prin intermediul
                contului tău instituțional oferit de către A.S.E. Platforma este destinată atât studenților
                care doresc să se înscrie sesiunea de licență a anului universitar curent, cât și profesorilor
                coordonatori.
              </Typography>
              <Typography sx={{ mb: '0.5vh' }} align='left' fontWeight='bold' component='h5' variant='h5'>
                2. Completarea datelor personale
              </Typography>
              <Typography sx={{ mb: '6vh' }} align='left' component='h5' variant='h5'>
                Completează-ți datele personale în pagina de profil, din meniul din dreapta, dând click pe iconița de meniu.
                Este necesară completarea atât a specializării pentru a putea vizualiza profesorii disponibili,
                cât și a restului datelor personale pentru identificare.
              </Typography></Box>
          </Box>
          <Box component={Paper} elevation={6}
            sx={{
              my: '1vh',
              mb: '2vh',
              justifyContent: 'space-around',
              borderRadius: 2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ mt: '4vh', mb: '2vh', bgcolor: 'secondary.main' }}>
              <AppSettingsAltIcon />
            </Avatar>
            <Typography sx={{ mb: '4vh' }} align='center' component='h1' variant='h4'>
              Fluxul aplicației - I. Solicitare colaborare.
            </Typography>
            <Box sx={{ width: '100%', px: '4vw' }}>
              <Typography sx={{ mb: '0.5vh' }} align='left' fontWeight='bold' component='h5' variant='h5'>
                Alegerea profesorului
              </Typography>
              <Typography align='left' component='h5' variant='h5'>
                Vei regăsi un tabel cu toți profesorii din cadrul specializării tale, care mai au
                locuri disponibile. Poți sorta fiecare câmp sau filtra după numele acestora.
                După ce te-ai hotărât, apasă pe butonul <i>Solicită</i>, completează în fereastra afișată
                titlul dorit pentru lucrare și un mesaj pentru profesor.
              </Typography>
              <Typography sx={{ mb: '6vh' }} align='left' component='h5' variant='h5'>
                După trimiterea solicitării, tot ce rămâne este să aștepți răspunsul. Vei primi notificare
                pe e-mail, apoi pagina ta din aplicație va avea alt aspect. Succes!
              </Typography>
              <Typography sx={{ mb: '6vh' }} color='orange' align='left' component='h5' variant='h5'>
                <i> Poți retrage solicitarea după cel puțin 48h de la trimiterea acesteia. Te rugăm
                  să recurgi la acest gest doar dacă ești foarte sigur.
                </i>
              </Typography>
            </Box>
          </Box>
          <Box component={Paper} elevation={6}
            sx={{
              my: '1vh',
              mb: '2vh',
              justifyContent: 'space-around',
              borderRadius: 2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ mt: '4vh', mb: '2vh', bgcolor: 'secondary.main' }}>
              <AppSettingsAltIcon />
            </Avatar>
            <Typography sx={{ mb: '4vh' }} align='center' component='h1' variant='h4'>
              Fluxul aplicației - II. Solicitare acceptată.
            </Typography>
            <Box sx={{ width: '100%', px: '4vw' }}>
              <Typography sx={{ mb: '0.5vh' }} align='left' fontWeight='bold' component='h5' variant='h5'>
                1. Generează cererea
              </Typography>
              <Typography align='left' component='h5' variant='h5'>
                Aici poți să îți generezi cererea de licență, precompletată cu datele date din pagina de profil,
                cu numele profesorului care ți-a acceptat solicitarea de colaborare, dar și cu titlul pe care îl
                introduci imediat înainte.
              </Typography>
              <Typography sx={{ mb: '6vh' }} color='orange' align='left' component='h5' variant='h5'>
                <i> Ai grijă la greșelile de tastare sau gramaticale!</i>
              </Typography>
              <Typography sx={{ mb: '0.5vh' }} align='left' fontWeight='bold' component='h5' variant='h5'>
                2. Încarcă cererea
              </Typography>
              <Typography sx={{ mb: '6vh' }} align='left' component='h5' variant='h5'>
                În cadrul acestei secțiuni, este necesar să reintroduci titlul licenței, în cazul în care
                nu este precompletat cu cel utilizat pentru generarea cererii, ca mai apoi să încarci
                documentul PDF semnat de tine. Odată încarcată, tot ce rămâne este să aștepți ca profesorul tău
                să o semneze.
              </Typography>
              <Typography sx={{ mb: '0.5vh' }} align='left' fontWeight='bold' component='h5' variant='h5'>
                3. Cererea ta semnată
              </Typography>
              <Typography sx={{ mb: '1vh' }} align='left' component='h5' variant='h5'>
                Ultima secțiune conține cererea ta semnată de către profesorul coordonator, în cazul în care a fost aprobată.
                Este posibil să fie respinsă, asa că trebuie să verifici și pe e-mail dacă ai fost informat de acest lucru.
              </Typography><Typography sx={{ mb: '6vh' }} align='left' component='h5' variant='h5'>
                Acest proces este valabil și în cazul în care trimiți o cerere pentru modificarea tematicii de licență,
                după ce ți-a fost aprobată una în prealabil.
              </Typography></Box>
          </Box>
          <Box component={Paper} elevation={6}
            sx={{
              my: '1vh',
              mb: '2vh',
              justifyContent: 'space-around',
              borderRadius: 2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ mt: '4vh', mb: '2vh', bgcolor: 'secondary.main' }}>
              <MarkEmailReadIcon />
            </Avatar>
            <Typography sx={{ mb: '4vh' }} align='center' component='h1' variant='h4'>
              Mail
            </Typography>
            <Box sx={{ width: '100%', px: '4vw' }}>
              <Typography sx={{ mb: '6vh' }} align='left' component='h5' variant='h5'>
                Nu uita să verifici mail-ul! Știm că fluxul de mesaje poate fi copleșitor, însă
                vei primi doar în anumite cazuri și informare prin poșta electronică.
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  )
}

