import React, { useState, useEffect } from 'react';
import { Box, Title, Text, Button } from '@mantine/core';
import TabelResponsive from '../../components/TabelResponsive';
import RequestModal from '../../components/RequestModal';
import Typography from '@mui/material/Typography';
import Modal from '../../ui/Modal';
import { toast } from 'react-toastify';

function RequestPage({ student, professor, requestOptions, refresh }) {
    const SERVER = process.env.REACT_APP_SERVER;
    const [availableProfessors, setAvailableProfessors] = useState([]);
    const [requestModal, setRequestModal] = useState({ active: false, professor: null });
    const [deleteError, setDeleteError] = useState(null);
    const [hasDeletedRequest, setHasDeletedRequest] = useState(false);
    const [solicitare, setSolicitare] = useState(null);

    const handleSolicita = (id) => {
        setRequestModal({ 
            active: true,
            professor: availableProfessors.filter((prof) => prof.id === id)[0],
        });
    };

    const getBackendData = async () => {
        if (student.poateTrimite) {
            const res = await fetch(SERVER + 'user/profesori', requestOptions('GET'));
            const data = await res.json();
            if (data && data.listaProfesori)
                setAvailableProfessors(data.listaProfesori);
        }
        if (!student.poateTrimite) {
            const res = await fetch(SERVER + 'solicitare/status', requestOptions('GET'))
            const data = await res.json();
            setSolicitare(data.solicitare);
        }
    };

    useEffect(() => {
        student && getBackendData();
    }, [student, availableProfessors]);

    const makeRequest = async (prof, title, message) => {
        const res = await fetch(SERVER + 'solicitare', {
            ...requestOptions('POST'),
            body: JSON.stringify({ idProfesor: prof.id, titlu: title, mesaj: message })
        });
        const data = await res.json();
        if(res.ok){
            toast.success("Solicitare trimisă!");
            refresh();
        } else toast.error("Nu s-a putut trimite solicitarea!");
    };

    const updateRequestModal = (modal) => {
        setRequestModal({ ...modal });
    };

    const cancelRequest = async (e) => {
        e.preventDefault();
        const res = await fetch(SERVER + 'solicitare', {
            ...requestOptions('DELETE'),
            body: JSON.stringify({ idSolicitare: solicitare.id }),
        });
        const data = await res.json();
        if (data.error) setDeleteError(data.error);
        if (!data.error) getBackendData();
    };
/**
 * nerepartizat
 *              poate trimite
 *                      daca am profi afisez
 *                      nu am profi, afisez mesaj
 *              nu poate trimite
 *                      asteapta...
 */
    return (
        <Box bg="white" mt="35px">
            {availableProfessors && availableProfessors.length > 0 && student?.e_repartizat === 0 && student?.poateTrimite &&
                <Box>
                    <Title order={3}>  Solicită colaborarea unuia dintre profesorii disponibili de mai jos.</Title>
                    <Text mb="25px">Odată lansată solicitarea, nu o poți retrage decât peste 2 zile.</Text>
                    <Box align="center">
                        <TabelResponsive
                            columns={[{ label: 'Nume', id: 'nume' }, { label: 'Locuri', id: 'numarLocuri' }, { label: '', id: 'button' }]}
                            rows={availableProfessors.map((prof) => ({
                                id: prof.id, nume: `${prof.nume} ${prof.prenume}`, numarLocuri: prof.numarLocuriRamase
                            }))}
                            handler={handleSolicita} title='Profesorii disponibili din specializarea ta' />
                    </Box>
                </Box>
            }
            {!availableProfessors || availableProfessors.length <= 0 && (student?.e_repartizat === 0 && student?.poateTrimite) &&
                <Text mt="30px" style={{ color: 'darkred', fontWeight: 'bold' }} align='center'>
                    Nu mai sunt profesori disponibili pentru specializarea ta. Directorul de departament te va repartiza manual.
                </Text>}
            {student?.e_repartizat === 0 && !student?.poateTrimite ? (
                <Box align="center">
                    <Typography sx={{ mt: 6 }} align='center' component='p' variant='p'  >
                        Ai trimis o solicitare profesorului {professor && professor.nume + ' ' + professor.prenume}. Te rugăm să aștepți un răspuns din partea sa. Succes!
                    </Typography>
                    <lottie-player src='https://assets8.lottiefiles.com/private_files/lf30_rgvpmzja.json' background='transparent'
                        speed='1' style={{ width: '300px', height: '300px' }} loop autoplay></lottie-player>
                    {deleteError && <Typography sx={{ mt: 3 }} align='center' component='p' variant='p' color='error'>{deleteError}</Typography>}
                    <Typography sx={{ mt: 3 }} align='center' component='p' variant='p' className='text-warning'>
                        Dacă nu primești răspuns în termen de 2 zile, ai posibilitatea să soliciți colaborarea unui alt profesor disponibil.
                    </Typography>
                    <Button type='button' mt="lg" onClick={() => setHasDeletedRequest(true)}>
                        Retrage solicitarea
                    </Button>
                    {hasDeletedRequest && (
                        <Modal open={true} closed={() => setHasDeletedRequest(false)}>
                            <Box>
                                <Typography align='center' component='h5' variant='h5' sx={{ mb: 2 }}>
                                    Ești sigur că vrei să îți retragi solicitarea de colaborare?
                                </Typography>
                                <Box>
                                    <Button variant='contained' color='success' sx={{ width: '48%', mx: '1%' }} onClick={(e) => { cancelRequest(e); setHasDeletedRequest(false); }}>
                                        Da
                                    </Button>
                                    <Button variant='contained' color='error' sx={{ width: '48%', mx: '1%' }} onClick={() => setHasDeletedRequest(false)}>
                                        Nu
                                    </Button>
                                </Box>
                            </Box>
                        </Modal>
                    )}
                </Box>
            ) : ('')}
            {requestModal.active && (
                <RequestModal makeRequest={makeRequest} updateRequestModal={updateRequestModal} modal={requestModal} />
            )}
        </Box>
    );
}

export default RequestPage;