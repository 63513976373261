import { Box, Text } from '@mantine/core';
import { toast } from 'react-toastify';
import { IconButton } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';

function DownloadPage({ student, cerereSemnata }) {
    const SERVER = process.env.REACT_APP_SERVER;

    const onDownload = () => {
        fetch(SERVER + 'cerere', {
            method: 'GET',
            headers: {
                Authorization: localStorage.getItem('token'),
                'Content-Type': 'application/json',
            },
        })
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                if (!data?.data?.fisier) {
                    toast.error('Nu ai cererea încărcată1');
                    return;
                }
                const downloadLink = document.createElement('a');
                const fileName = `Cerere licenta ${student.nume + ' ' + student.prenume
                    } - ${data.data.titlu}.pdf`;
                downloadLink.href = data.data.fisier;
                downloadLink.download = fileName;
                downloadLink.click();
            });
    };

    return (
        <Box bg="white" p="50px" mt="55px" align="center">
        {student && student?.e_repartizat && cerereSemnata ? (
          <Box>
            <Text>Cererea ta {cerereSemnata ? '' : 'nu'} este semnată de către profesorul coordonator.</Text>
            <IconButton color="primary" onClick={onDownload}>Descarcă cererea <DownloadIcon /></IconButton>
            <lottie-player src="https://assets5.lottiefiles.com/packages/lf20_w9yhybo7.json" background="transparent" speed="1" style={{ height: '300px' }} loop autoplay></lottie-player>
          </Box>
        ) : (
          <Box sx={{ my: 4, mx: 0, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            Cererea ta {cerereSemnata ? '' : 'nu'} este semnată de către profesorul coordonator.
            <lottie-player src="https://assets5.lottiefiles.com/packages/lf20_jogp4tqe.json" background="transparent" speed="0.6" style={{ height: '300px' }} loop autoplay></lottie-player>
          </Box>
        )}
      </Box>      
    );
}

export default DownloadPage;