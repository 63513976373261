import { AppShell, Burger, Tooltip, Transition } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { ProfBurger } from './ProfBurger';
import { StudBurger } from './StudBurger';

export function AppNavbar(props) {
    const [opened, { toggle }] = useDisclosure();

    return (
        <AppShell 
            header={{ height: 60 }}
            navbar={{
                width: 300,
                breakpoint: 'sm',
                collapsed: { mobile: !opened },
            }}
            withBorder={false}
            padding="md"
        >
            <AppShell.Header style={{ display: 'flex', alignItems: 'center', backgroundColor: 'rgb(22, 73, 120)', color: 'white' }}>
                {props.isLoggedIn && (
                    <Tooltip label="Meniu" transitionProps={{
                        duration: 250,
                        timingFunction: 'ease-out',
                        transition: 'slide-down',
                    }}>
                        <Burger
                            color='white'
                            opened={opened}
                            onClick={toggle}
                            size="sm"
                            style={{ marginLeft: '16px' }}
                        /></Tooltip>
                )}
                <img
                    src="https://csie.ase.ro/wp-content/uploads/2020/10/cropped-CSIE_new-300x132.png"
                    style={{ width: 100, marginRight: '16px', marginLeft: '10px' }} alt="logo"
                />
                <div>
                    {props.isLoggedIn && 'Licență C.S.I.E.'}
                    {!props.isLoggedIn &&
                        'Facultatea de Cibernetică, Statistică și Informatică Economică'}
                </div>
            </AppShell.Header>
            {opened && (
                <AppShell.Navbar p="md" withBorder>
                    {props.isLoggedIn && !props.isStudent && <ProfBurger close={toggle}/>}
                    {props.isLoggedIn && props.isStudent && <StudBurger close={toggle}/>}
                </AppShell.Navbar>
            )}
        </AppShell>

    );
}