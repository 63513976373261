import { useState } from 'react';
import { Group, Box, Collapse, ThemeIcon, Text, UnstyledButton, rem, Badge } from '@mantine/core';
import { IconChevronRight } from '@tabler/icons-react';
import classes from './NavbarLinksGroup.module.css';
import { useNavigate } from 'react-router-dom';
import { googleLogout } from '@react-oauth/google';

export function LinksGroup({ icon: Icon, label, initiallyOpened, links, link, logout, close, notifications }) {
  const hasLinks = Array.isArray(links);
  const navigate = useNavigate();
  const [opened, setOpened] = useState(initiallyOpened || false);
 
  const items = (hasLinks ? links : []).map((link) => (
    <Text style={{ cursor: "pointer" }} className={classes.link} onClick={() => { close(); navigate(link.link) }} key={link.label}>
      {link.label}{link.notifications > 0 && (
        <Badge ml={7} size="sm" variant="filled" className={classes.mainLinkBadge}>
          {link.notifications}
        </Badge>
      )}
    </Text>));

  const onLogout = () => {
    googleLogout();
    localStorage.removeItem("token");
    localStorage.clear();
    sessionStorage.clear();
    document.cookie = null;
    window.location.href = "/";
  };

  return (<>
    <UnstyledButton onClick={() => setOpened((o) => !o)} className={classes.control}>
      <Group justify="space-between" gap={0}>
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <ThemeIcon variant="light" size={30}>
            <Icon style={{ width: rem(18), height: rem(18) }} />
          </ThemeIcon>
          <Box ml="md"><Text className={classes.link} key={label}
            onClick={() => {
              if (logout) {
                onLogout();
              } else if (link) {
                close();
                navigate(link);
              }
            }} >{label}{notifications > 0 && (
              <Badge ml={7} size="sm" variant="filled" className={classes.mainLinkBadge}>
                {notifications}
              </Badge>
            )}</Text></Box>
        </Box>
        {hasLinks && (<IconChevronRight className={classes.chevron} stroke={1.5} style={{
          width: rem(16),
          height: rem(16),
          transform: opened ? 'rotate(-90deg)' : 'none',
        }} />)}
      </Group>
    </UnstyledButton>
    {hasLinks ? <Collapse in={opened}>{items}</Collapse> : null}
  </>);
}