import { IconNotes, IconHelp, IconLogout, IconUser } from '@tabler/icons-react';
import classes from './NavbarSearch.module.css';
import { LinksGroup } from './LinksGroup';

export function StudBurger({close}) {
    const mockdata = [
        { label: 'Profil', icon: IconUser, link: '/profile' },
        { label: 'Cerere', icon: IconNotes, link: '/' },
        { label: 'Ajutor', icon: IconHelp, link: '/info'},
        { label: 'Ieși din cont', icon: IconLogout, logout: true }
    ];

    const links = mockdata.map((item) => <LinksGroup close={close} {...item} key={item.label} />);
    return (
        <nav className={classes.navbar}>
            <div className={classes.section}>
                <div className={classes.mainLinks}>{links}</div>
            </div>
        </nav>
    );
}