import React from 'react';
import App from './App';
import ReactDOM from 'react-dom/client';
import { MantineProvider } from '@mantine/core';
import { BrowserRouter } from 'react-router-dom';
import { GoogleOAuthProvider } from "@react-oauth/google";
import '@mantine/core/styles.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <GoogleOAuthProvider clientId='353019817792-1pnm9fgaffk5uu4uam9gbvfhc2o92c2p.apps.googleusercontent.com'>
      <MantineProvider>
        <App />
      </MantineProvider>
    </GoogleOAuthProvider>
  </BrowserRouter>
);

if (process.env.REACT_APP_SERVER === '/') console.log = () => { };